import React from "react"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import CategoryList from "~/components/category-list"
import ProductList from "~/components/product-list"
import PageHeading from "~/components/styled/page-heading"
import CategoryRename from "~/components/category-rename"
import CategoryMetaDescription from  "~/components/category-meta-description"
import GoogleAdsense from "~/components/google-adsense"

const IndexPage = ({ data }) => {
  const products = data.allStrapiProduct.edges.map(({node}) => node)
  const seo = {
    title: "优质产品"
  }

  return (
    <Layout>
      <SEO seo={seo} />
      <CategoryList slug="0vgnv0po" />

      <div className="mt-8 mb-10">
        <PageHeading>{CategoryRename(data.strapiCategory.name)}</PageHeading>
        <ProductList products={products} />
      </div>

      <GoogleAdsense slot="5243618393" responsive="true" />

      <div className="mb-16 mt-8">
        <CategoryList slug={"0vgnv0po"} showMenu={true} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCategory(slug: {eq: "0vgnv0po"}) {
        name
    }

    allStrapiProduct(
      filter: {categories: {elemMatch: {slug: {eq: "0vgnv0po"}}}}
      sort: {fields: created_at, order: DESC}
    ) {
      edges {
        node {
          name
          slug
          price
          originalPrice
          sold
          ImageDesc {
            desc
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 360
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                  quality: 75
                )
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
